/* Provide sufficient contrast against white background */
a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

#reportViewer1 {
	position: absolute;
	top: 70px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	overflow: hidden;
	clear: both;
}
